import React from 'react'

import { Icon } from '@juullabs/react-components'
import { NavigationEntry } from '@viewlio/types/src/contentful'

import { LocalizedLink } from 'components/common/LocalizedLink'
import { withStoreCustomization } from 'utils/hocs/withStoreCustomization'
import { useFromOauthCookie } from 'utils/hooks'

import { getProps } from '../Header/Header.serializer'

import styles from './Logo.module.scss'
import { TemporaryLogoIconErrorBoundary } from './TemporaryLogoIconErrorBoundary'

type Props = {
  navigationEntry: NavigationEntry
}

type LogoProps = {
  src?: string
}

const ItalianLogo: React.FC<LogoProps> = ({
  src,
}) =>
  <img src={src} className={styles.logo} />

const DefaultLogo: React.FC<LogoProps> = () => (
  // eslint-disable-next-line max-len
  // This temporary error boundary is aiming to investigate this bugsnag: https://app.bugsnag.com/juul-labs/viewlio/errors/63ef9c45b5ff0c0009f1b2a4?event_id=63feb3b500b8054835850000&i=sk&m=ef
  // It may be an issue with NextJS, as others are experiencing a similar issue
  // with missing chunks: https://github.com/vercel/next.js/issues/38507

  // With this error-boundary, we are aiming to:
  // (i) catch the error and provide a fallback experience
  // (ii) get a fullstory (currently missing on all bugsnags)
  // (ii) potentially get more info from the thrown error

  // After it is resolved, we should
  // 1. Remove the TemporaryLogoIconErrorBoundary
  // 2. Remove that component's associated test/styles
  // 3. Remove this comment
  <TemporaryLogoIconErrorBoundary>
    <Icon className={styles.logo} name='header_logo/juul-54x24-black_base_100' />
  </TemporaryLogoIconErrorBoundary>
)

const LogoIcon = withStoreCustomization({
  'juul-it': ItalianLogo,
}, DefaultLogo)

export const Logo: React.FC<Props> = ({ navigationEntry }) => {
  const props = getProps(navigationEntry)

  const { isFromOauth } = useFromOauthCookie()

  return (
    <div className={styles.wrapper} data-testid='header-logo'>
      {!isFromOauth ? (
        <LocalizedLink href='/'>
          <LogoIcon src={props.logo.url}/>
        </LocalizedLink>
      ) : (
        <LogoIcon src={props.logo.url}/>
      )}
    </div>
  )
}
